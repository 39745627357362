@import '@/styles/variables';

.bold {
  font-weight: bold;
}

.container {
  z-index: $z-index-tooltip;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: var(--bs-tertiary-bg);
  border: 1px solid var(--bs-border-color);
  border-radius: $card-border-radius;
}
