@import '@/styles/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  color: var(--bs-body-color);
  background-color: var(--bs-secondary-bg);
}

.milestone {
  animation: milestone-achieved 0.75s infinite alternate steps(15);
}

@keyframes milestone-achieved {
  from {
    background-color: rgb(255 211 161);
  }

  to {
    background-color: rgb(188 172 152);
  }
}
