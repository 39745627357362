@import '@/styles/variables';

.panel {
  min-height: 450px;
}

.voteCount {
  width: 100%;
  max-width: 300px;
  padding-top: 1rem;
  margin: 3rem 0 1rem;
  font-style: italic;
  color: $secondary;
  text-align: center;
  border-top: 1px solid $card-border-color;
}
