@import '@/styles/variables';

.closeContainer {
  position: absolute;
  top: 1px;
  right: 1px;
  font-size: 1em;
  color: var(--bs-secondary-color);
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
}

.frameStyle {
  width: 475px;
  height: 267px;

  @media (width <= 525px) {
    width: 325px;
    height: 200px;
  }
}

.infoContainer {
  position: absolute;
  right: 1rem;
  z-index: $z-index-tooltip;
  width: 500px;
  background-color: var(--bs-tertiary-bg);
  border: 1px solid var(--bs-border-color);
  border-radius: $card-border-radius;

  @media (width <= 525px) {
    width: 350px;
  }
}

.trigger {
  position: absolute;
  right: 1rem;
  z-index: $z-index-tooltip;
  font-size: 2em;
  color: var(--bs-secondary-color);
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--bs-border-color);
  border-radius: $card-border-radius;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: var(--body-bg);
  }
}
