.button {
  img {
    transition: filter 0.15s ease-in-out;
  }

  @media (hover: hover) {
    // Only if hover is supported
    &:hover {
      img {
        filter: invert(100%);
      }
    }
  }

  @media (hover: none) {
    // Only if hover is not supported (on iphone so hover effect doesn't persist)
    &:hover {
      color: inherit !important;
      background-color: transparent !important;
    }
  }
}

[data-bs-theme='dark'] {
  .button {
    img {
      filter: invert(100%);
    }

    @media (hover: hover) {
      // Only if hover is supported
      &:hover {
        img {
          filter: unset;
        }
      }
    }

    @media (hover: none) {
      // Only if hover is not supported (on iphone so hover effect doesn't persist)
      &:hover {
        color: inherit !important;
        background-color: transparent !important;
      }
    }
  }
}
